import {
  UPDATE_BASKET,
} from 'Stores/types/basketMutationsTypes';

import { LOGIN_SUCCESS } from 'Stores/types/sessionMutationsTypes';
import { UPDATE_USER_ACTION } from 'Stores/types/userActionsTypes';
import { UPDATE_SUBSCRIPTION_BASKET } from 'Stores/types/subscriptionBasketMutationsTypes';

import {
  UPDATE_SPONSOR, VALID_SPONSOR_CODE,
  SET_TEMPORARY_CUSTOMER,
  UPDATE_TEMPORARY_CUSTOMER,
  UPDATE_TEMPORARY_CUSTOMER_EMAIL,
  RETRIEVE_LOCALSTORAGE,
  UPDATE_TRACKED_URL,
} from 'Stores/types/temporaryCustomerMutationsTypes';

import {
  UPDATE_SPONSOR_ACTION,
  VALID_SPONSOR_CODE_ACTION,
  CREATE_TEMPORARY_CUSTOMER_ACTION,
  RESET_TEMPORARY_CUSTOMER_ACTION,
  GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION,
  UPDATE_EMAIL_ACTION,
  UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION,
  REGISTER_USER_INFOS_SEND_MAIL,
  GET_TEMPORARY_CUSTOMER_ACTION,
  AFTER_CONFIRM_LOGGIN_ACTION,
  UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION,
  UPDATE_TEMPORARY_CUSTOMER_ACTION,
  UPDATE_TEMPORARY_CUSTOMER_DATA,
} from 'Stores/types/temporaryCustomerActionsTypes';

import Providers from 'Classes/social-providers/Providers';

import { api } from 'Plugins/potagerApiClient';
import { UPDATE_ADDRESS } from 'Stores/types/homeDeliveryMutationsTypes.js';

const defaultState = {
  isLoaded: false,
  firstName: null,
  lastName: null,
  id: localStorage.getItem('temporaryCustomerId'),
  email: null,
  isMailConfirmed: false,
  regionId: null,
  from: 'home',
  phone: null,
  groupDeliveryDay: null,
  sponsorshipCode: null,
  stats: {
    statsFirstVisitBeforeInscriptionUrl: null,
    statsLastVisitBeforeInscriptionUrl: null,
  },
  ...Providers.map((e) => e.config.name)
    .reduce((result, item) => {
      result[`${item}Id`] = null;
      return result;
    }, {}),
};

export default {
  namespaced: true,
  state: {
    ...defaultState,
  },

  getters: {
    getTemporaryCustomerId: (state) => Number(state.id || localStorage.getItem('temporaryCustomerId')) || null,
    getEmail: (state) => state.email,
    getFirstName: (state) => state.firstName,
    getLastName: (state) => state.lastName,
    getPhone: (state) => state.phone,
    getRegionId: (state) => state.regionId,
    isMailConfirmed: (state) => state.isMailConfirmed,
    getFrom: (state) => state.from,
    getSponsorshipCode: (state) => state.sponsorshipCode,
    getStats: (state) => state.stats,
    isTemporaryCustomerLoaded: (state, getters) => state.isLoaded && !!getters.getTemporaryCustomerId,
  },

  mutations: {
    [SET_TEMPORARY_CUSTOMER](state, id) {
      state.isLoaded = !!id;
      state.id = id;
      localStorage.setItem('temporaryCustomerId', id);
    },
    [UPDATE_TEMPORARY_CUSTOMER_EMAIL](state, email) {
      state.email = email;
    },
    [UPDATE_TEMPORARY_CUSTOMER](state, data) {
      Object.keys(data)
        .forEach((key) => {
          if (state.hasOwnProperty(key)) {
            state[key] = data[key];
          }
        });
      Providers.forEach((e) => {
        state[`${e.config.name}Id`] = data[`${e.config.name}Id`];
      });
    },
    [UPDATE_SPONSOR](state, data) {
      state.sponsorshipCode = data.sponsorshipCode;
    },
    [RETRIEVE_LOCALSTORAGE](state) {
      let temporaryCustomerStorage = localStorage.getItem('temporaryCustomer');
      if (temporaryCustomerStorage) {
        temporaryCustomerStorage = JSON.parse(temporaryCustomerStorage);
        state.stats = temporaryCustomerStorage.stats;
      }
    },
    [UPDATE_TRACKED_URL](state) {
      let trackedUrl = window.location.href;
      // const utmRegex = /(utm|gclid|msclkid|awc)/;
      // const hasUtm = window.location.search.match(utmRegex);

      if (document.referrer) {
        trackedUrl = document.referrer;
      }

      if (!state.stats.statsFirstVisitBeforeInscriptionUrl) {
        state.stats.statsFirstVisitBeforeInscriptionUrl = trackedUrl;
      }
      state.stats.statsLastVisitBeforeInscriptionUrl = trackedUrl;
      localStorage.setItem('temporaryCustomer', JSON.stringify({ stats: state.stats }));
    },
  },

  actions: {
    [CREATE_TEMPORARY_CUSTOMER_ACTION]({
      commit,
      dispatch
    }) {
      commit(RETRIEVE_LOCALSTORAGE);
      commit(UPDATE_TRACKED_URL);

      return new Promise((resolve, reject) => {
        if (!localStorage.getItem('temporaryCustomerId')) {
          api.temporaryCustomer.create()
            .then((resp) => {
              dispatch(UPDATE_TEMPORARY_CUSTOMER_DATA, resp);
              resolve(resp);
            })
            .catch((err) => reject(err));
        } else {
          resolve();
        }
      });
    },
    [RESET_TEMPORARY_CUSTOMER_ACTION]({ dispatch }) {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('temporaryCustomerId');
        dispatch(CREATE_TEMPORARY_CUSTOMER_ACTION)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    [UPDATE_EMAIL_ACTION]({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_EMAIL_ACTION, { root: true });
        api.temporaryCustomer.setEmail(localStorage.getItem('temporaryCustomerId'), payload.email)
          .then((resp) => {
            dispatch(UPDATE_TEMPORARY_CUSTOMER_DATA, resp);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_EMAIL_ACTION, { root: true }));
      });
    },
    [UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION]({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION, { root: true });
        dispatch(REGISTER_USER_INFOS_SEND_MAIL, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
          .finally(() => {
            dispatch('wait/end', UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION, { root: true });
          });
      });
    },
    [REGISTER_USER_INFOS_SEND_MAIL]({
      dispatch,
      getters
    }, payload) {
      return new Promise((resolve, reject) => {
        api.temporaryCustomer.registerUserInfosSendMail(getters.getTemporaryCustomerId, {
          ...payload,
          statsFirstVisitBeforeInscriptionUrl: getters.getStats.statsFirstVisitBeforeInscriptionUrl,
          statsLastVisitBeforeInscriptionUrl: getters.getStats.statsLastVisitBeforeInscriptionUrl,
        })
          .then((response) => {
            dispatch(AFTER_CONFIRM_LOGGIN_ACTION, response)
              .then((resp) => resolve(resp))
              .catch((err) => reject(err));
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [AFTER_CONFIRM_LOGGIN_ACTION]({
      commit,
      dispatch
    }, respTemp) {
      return new Promise((resolve, reject) => {
        commit(`session/${LOGIN_SUCCESS}`, null, { root: true });

        const { token } = respTemp.data.data;
        localStorage.setItem('token', token); // store the token in localstorage
        localStorage.removeItem('temporaryCustomerId'); // remove offline user

        // On update l'utilisateur courant
        dispatch(`user/${UPDATE_USER_ACTION}`, null, { root: true })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    [GET_TEMPORARY_CUSTOMER_ACTION]({
      getters,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', GET_TEMPORARY_CUSTOMER_ACTION, { root: true });
        if (getters.getTemporaryCustomerId) {
          api.temporaryCustomer.findOneById(getters.getTemporaryCustomerId)
            .then((resp) => {
              dispatch(UPDATE_TEMPORARY_CUSTOMER_DATA, resp);
              resolve(resp);
            })
            .catch((error) => {
              if (error?.response?.status === 404 || error?.message === 'Network Error') {
                // Signifie que l'id a expiré côté back
                // donc on reset le temporary customer
                dispatch(RESET_TEMPORARY_CUSTOMER_ACTION)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              } else {
                reject(error);
              }
            })
            .finally(() => dispatch('wait/end', GET_TEMPORARY_CUSTOMER_ACTION, { root: true }));
        } else {
          dispatch(CREATE_TEMPORARY_CUSTOMER_ACTION)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        }
      });
    },
    [GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION]({ dispatch }, token) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION, { root: true });
        api.temporaryCustomer.findOneByToken(token)
          .then((resp) => {
            dispatch(UPDATE_TEMPORARY_CUSTOMER_DATA, resp);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION, { root: true }));
      });
    },
    [UPDATE_SPONSOR_ACTION]({
      commit,
      dispatch,
      rootGetters,
    }, sponsorshipCode) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_SPONSOR, { root: true });
        const temporaryCustomerId = rootGetters['temporaryCustomer/getTemporaryCustomerId'];
        api.temporaryCustomer.setSponsorshipCodeAction(temporaryCustomerId, { sponsorshipCode })
          .then((resp) => {
            if (!resp.data.success) {
              reject(resp.data.errors);
            }

            commit(UPDATE_SPONSOR, resp.data.data);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_SPONSOR, { root: true }));
      });
    },
    [VALID_SPONSOR_CODE_ACTION]({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', VALID_SPONSOR_CODE, { root: true });
        api.sponsorship.getSponsorFromCode(payload)
          .then((resp) => {
            if (!resp.data.success) {
              reject(resp.data.errors);
            }

            resolve(resp.data.data);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', VALID_SPONSOR_CODE, { root: true }));
      });
    },
    [UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION]({
      dispatch,
      rootGetters,
    }, region) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION, { root: true });

        const setRegion = () => api.temporaryCustomer
          .setRegion(localStorage.getItem('temporaryCustomerId'), region)
          .then((resp) => {
            dispatch(UPDATE_TEMPORARY_CUSTOMER_DATA, resp);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION, { root: true });
          });

        const gdd = rootGetters['basket/getGroupDeliveryDay'] || rootGetters['subscriptionBasket/getGroupDeliveryDay'];

        if (gdd && gdd.regionId !== region) {
          dispatch(RESET_TEMPORARY_CUSTOMER_ACTION)
            .then(() => {
              setRegion();
            })
            .catch((err) => {
              dispatch('wait/end', UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION, { root: true });
              reject(err);
            });
        } else {
          setRegion();
        }
      });
    },
    [UPDATE_TEMPORARY_CUSTOMER_ACTION]({ dispatch }, params) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_TEMPORARY_CUSTOMER_ACTION, { root: true });
        api.temporaryCustomer.updateInfos(localStorage.getItem('temporaryCustomerId'), params)
          .then((resp) => {
            dispatch(UPDATE_TEMPORARY_CUSTOMER_DATA, resp);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', UPDATE_TEMPORARY_CUSTOMER_ACTION, { root: true });
          });
      });
    },
    [UPDATE_TEMPORARY_CUSTOMER_DATA]({ commit }, resp) {
      const data = resp.data.data ? resp.data.data : resp.data;

      commit(SET_TEMPORARY_CUSTOMER, data.id);
      commit(UPDATE_TEMPORARY_CUSTOMER, data);
      commit(UPDATE_TEMPORARY_CUSTOMER_EMAIL, data.email);
      commit(`basket/${UPDATE_BASKET}`, data.basket, { root: true });
      commit(`subscriptionBasket/${UPDATE_SUBSCRIPTION_BASKET}`, data.subscriptionBasket, { root: true });
      if (data.address) commit(`homeDelivery/${UPDATE_ADDRESS}`, data.address, { root: true });
    },
  },
};
