<template>
  <div class="sponsorship-page bg-lavender-blush">
    <potager-container class="text-center max-w-[700px] p-0">
      <potager-background
        background-repeat="no-repeat"
        background-size="700px"
        position="top"
        src="sponsorship-banner-page">
        <article class="w-full max-w-[310px] flex flex-col items-center justify-center mx-auto py-16">
          <p
            v-if="sponsorshipFirstName"
            class="text-2xl mb-3 font-extrabold text-byzantine">
            Grâce à <b>{{ sponsorshipFirstName }}</b><br>
            testez Potager City
          </p>
          <p
            v-else
            class="bg-byzantine/20 rounded w-40 h-8 mb-3 animate-pulse" />
          <p class="text-2xl.5 font-lore-alternates font-bold mb-3">
            18€ offerts*
          </p>

          <ul class="text-sm.5 font-medium">
            <li>
              <b class="text-byzantine font-extrabold">
                10€
              </b> sur votre 1<sup>ère</sup> commande
            </li>

            <li>
              <b class="text-byzantine font-extrabold">
                5€
              </b> sur votre 2<sup>ème</sup> commande
            </li>

            <li>
              <b class="text-byzantine font-extrabold">
                3€
              </b> sur votre 3<sup>ème</sup> commande
            </li>
          </ul>

          <div class="w-[224px] border-b border-byzantine/30 my-5" />

          <p class="font-medium mb-2">
            Code parrain&nbsp;:
          </p>

          <potager-input
            v-model="sponsorshipCode"
            :disabled="isLoading"
            :loading="isLoading"
            class="w-[224px]"
            inline-submit="Copier"
            inline-submit-theme="charcoal"
            name="sponsorshipCode"
            type="text"
            @onSubmit="copyToClipboard" />

          <div class="w-[224px] border-b border-byzantine/30 my-5" />

          <potager-button
            :to="{ name: 'dashboard' }"
            :tooltip="isSponsorshipCodeValid ? undefined : 'Ceci&nbsp;semble être&nbsp;votre propre code&nbsp;de&nbsp;parrainage'"
            class="mb-10"
            lowercase
            theme="byzantine">
            Commander
          </potager-button>

          <p class="text-xxs.5 italic">
            *{{ getSponsorshipGodSonValue }} de remise valable sur la 1ère commande : minimum d'achat de
            {{ getSponsorshipMinGodSonValue }}. 5€ de remise valable 15 jours après la livraison de la 1ère commande :
            minimum d'achat de 15€. 3€ de remise valable 15 jours après la livraison de la 2ème commande : minimum
            d'achat de 13€.
          </p>
        </article>
      </potager-background>
    </potager-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolsMixin from 'Mixins/ToolsMixin';

import PotagerButton from 'UI/PotagerButton';
import PotagerBackground from 'UI/PotagerBackground';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerInput from 'UI/Form/PotagerInput';

import ModalCouponPolicy from 'Modals/ModalCouponPolicy';

import { VALID_SPONSOR_CODE_ACTION, UPDATE_SPONSOR_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';
import { UPDATE_SPONSOR } from 'Stores/types/temporaryCustomerMutationsTypes';
import { CouponType } from 'PotagerLogic/Enums/Coupons';

export default {

  data: () => ({
    sponsorshipFirstName: null,
  }),

  mixins: [
    ToolsMixin,
  ],

  components: {
    PotagerInput,
    PotagerContainer,
    PotagerButton,
    PotagerBackground,
  },

  computed: {
    ...mapGetters('app', [
      'getSponsorshipMinGodSonValue',
      'getSponsorshipGodSonValue',
      'getSponsorshipPromotedText',
    ]),
    ...mapGetters('user', [
      'getMySponsorshipCode',
    ]),
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
    ]),
    isLoading() {
      return this.$wait.is(UPDATE_SPONSOR);
    },
    sponsorshipCode() {
      return this.$potagerRoute.query.code;
    },
    isSponsorshipCodeValid() {
      return this.sponsorshipCode && this.sponsorshipCode !== this.getMySponsorshipCode;
    },
  },

  methods: {
    getValidSponsorshipCode(sponsorshipCode) {
      return this.$store.dispatch(`temporaryCustomer/${VALID_SPONSOR_CODE_ACTION}`, sponsorshipCode);
    },
    openModal() {
      this.$modal.open(ModalCouponPolicy, {
        promotedCoupon: {
          couponType: CouponType.Sponsorship,
          ...this.getSponsorshipPromotedText,
        },
      });
    },
    copyToClipboard() {
      const copyText = document.querySelector('input[name="sponsorshipCode"]');
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(copyText.value);
      this.$notify({
        type: 'success',
        title: 'Code de parrainage copié',
        text: 'Ajoutez-le à votre panier ou commande pour en profiter !',
      });
    },
  },

  watch: {
    getTemporaryCustomerId: {
      handler() {
        if (!this.sponsorshipCode) return;

        this.getValidSponsorshipCode(this.sponsorshipCode)
          .then(({ firstname }) => {
            this.sponsorshipFirstName = firstname;
            if (this.isSponsorshipCodeValid) {
              this.$store.dispatch(`temporaryCustomer/${UPDATE_SPONSOR_ACTION}`, this.sponsorshipCode);
            }
          })
          .catch(() => {
            this.sponsorshipFirstName = null;
            this.$notify({
              type: 'error',
              title: 'Code de parrainage invalide',
              text: 'L\'url de parrainage est incorrecte.',
            });
          });
      },
      immediate: true,
    },
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Profitez de -10€ sur votre première commande.',
      description: 'Inscrivez-vous dès maintenant sur Potager City et profitez de -10€ sur votre 1ère commande, puis de -5€ et -3€ sur les suivantes !',
      image: 'https://www.potagercity.fr/images/meta_sponsorship.png',
    });
  },
};
</script>

<style lang="scss" scoped>
.sponsorship-page {
  :deep() .potager-input {
    &__input {
      @apply font-bold;
    }
  }
}
</style>
